import * as moment from "moment";

export class StockinConfig {
  public static titleSection = {
    list: {
      title: "master.common.locationList",
      button: {
        text: "master.common.addLocation",
        url: "/location/location/add",
      },
    },
    add: {
      title: "master.common.locationList",
    },
    edit: {
      title: "master.common.locationList",
    },
    view: {
      title: "master.common.locationList",
    },
  };

  public static breadcrumbs = [
    {
      text: "master.common.masters",
      url: "/location/location/list",
      hasHref: true,
    },
    {
      text: "master.common.location",
      url: "/location/location/list",
      hasHref: true,
    },
    // {
    //   text: 'master.common.list',
    //   hasHref: false
    // }
  ];

  public static columns = [
    {
      name: 'id',
      displayName: 'language.s.no',
      type: 'number'
    },
    {
      name: "caneCrushingSeason",
      displayName: "sugarmill.stockin.canecrushingseason(molassesyear)",
      type: "string",
    },
    {
      name: "date",
      displayName: "sugarmill.common.dailyproductiondate",
      type: "date",
      dateFormat: 'dd/MM/yyyy',
    },
    {
      name: 'createdDate',
      displayName: 'Daily Production Created Date & Time',
      type: "date",
      dateFormat: 'dd-MM-yyyy hh:mm a',
    },
    {
      name: 'modifiedDate',
      displayName: 'Daily Production Approved/Rejected Date & Time',
      displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'REJECTED' ) ?  moment(item.modifiedDate).format("DD-MM-YYYY hh:mm a") : '-',
      type: 'objstring',
    },
    {
      name: "applicationNumber",
      displayName: "sugarmill.stockin.applicationo",
      type: "string",
    },
    {
      name: "productType",
      displayName: "sugarmill.stockin.productype",
      type: "string",
    },
    {
      name: "caneCrushedQtl",
      displayName: "Available Quantity(BL/Qtl)",
      displayFcn: (item: any) => {
        if (item.mf5StockInTankDetail && item.mf5StockInTankDetail.length > 1) {
          return item.mf5StockInTankDetail.reduce((total, current) => total + current.receivedMolassesQtl, 0);
        } 
        else if(item.mf5StockInTankDetail && item.mf5StockInTankDetail.length == 1) {
          return item.mf5StockInTankDetail[0].receivedMolassesQtl;
        }
      },
      type: "objstring",
    },
    {
      name: "stageDesc",
      displayName: 'language.currentlywith',
      displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
      type: 'objstring'
    },
    {
      name: "statusDesc",
      displayName: "sugarmill.common.status",
      type: 'options',
      badgeColors: {
        'Draft': 'badge-primary',
        'In Progress': 'badge-secondary',
        'Approved': 'badge-success',
        'Submitted': 'badge-info',
        'Rejected': 'badge-danger',
        'Clarification': 'badge-warning'
      }
      // badgeColors: {
      //   SUBMITTED: 'badge-success',
      //   INITIATED: 'badge-warning',
      //   APPROVED: "badge-primary",
      //   REJECTED: "badge-danger",
      //   DRAFT: "badge-warning"
      // }
    },
  ];
}
