import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';

@Injectable({
  providedIn: 'root'
})
export class ColdstoragetransferService {

  baseURL = environment.scmOtherModules;

  constructor(private http: HttpClient) { }

  coldStorageTransferLazySearch(postdata): Observable<any> {
    const url = this.baseURL + 'coldStorageTransfer/lazySearch';
    return this.http.post(url, postdata);
  }
  addColdStorageTransfer(postdata): Observable<any> {
    const url = this.baseURL + 'coldStorageTransfer';
    return this.http.post(url, postdata);
  }
  getColdStorageTransferById(id): Observable<any> {
    const url = this.baseURL + 'coldStorageTransfer/' + id;
    return this.http.get(url);
  }
  pulpMakingLazySearch(postdata): Observable<any> {
    const url = this.baseURL + 'pulpMaking/lazySearch';
    return this.http.post(url, postdata);
  }
  addpulpMaking(postdata): Observable<any> {
    const url = this.baseURL + 'pulpMaking';
    return this.http.post(url, postdata);
  }
  getPulpMakingById(id): Observable<any> {
    const url = this.baseURL + 'pulpMaking/' + id;
    return this.http.get(url);
  }

}
