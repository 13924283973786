export class Accidentalrequestconfig {

    public static columns = [
        {
            name: 'id',
            displayName: 'Request Id',
            type: 'string'
        },
        {
            name: 'request_date',
            displayName: 'Request Date',
            type: 'date'
        },
        {
            name: 'accident_type_value',
            displayName: 'Accident Type',
            type: 'string'
        },
        {
            name: 'district_name',
            displayName: 'Accident Locaion District',
            type: 'string'
        },
        {
            name: 'status',
            displayName: 'Status',
            displayFcn: (item: any) => item.status,
            type: 'objstring'
        }
    ]
}
