export class StockIn {


    public static Completed = [
        {
            name: 'id',
            displayName: 'wholesale.damagedproducts.sno',
            type: 'number'
        },
        {
            name: 'created_date',
            displayName: 'Transport Pass Date',
            displayFcn: (item: any) => item.created_date,
            type: 'date'
        },
        {
            name: 'ack_time1',
            displayName: 'TP ACK Date',
            displayFcn: (item: any) => item.ack_time1,
            type: 'date',
            dateFormat: 'dd/MM/yyyy',
        },
        {
            name: 'tp_reference_number',
            displayName: 'wholesale.stockin.transportpassnumber',
            displayFcn: (item: any) => item.tp_reference_number,
            type: 'objstring'
        },
        // {
        //     name: 'tp_num_seq',
        //     displayName: 'wholesale.stockin.totalnoofindent',
        //     displayFcn: (item: any) => item.tp_num_seq,
        //     type: 'objstring',
        //     align: 'center'
        // },
        {
            name: 'total_cases',
            displayName: 'wholesale.stockin.noofcasesMonocartonrecevied',
            displayFcn: (item: any) => item.total_cases,
            type: 'objstring',
            align: 'center'
        },
        {
            name: 'total_bottles',
            displayName: 'wholesale.stockin.noofbottlesrecevied',
            displayFcn: (item: any) => item.total_bottles,
            type: 'objstring',
            align: 'center'

        },
        {
            name: 'status',
            displayName: 'wholesale.stockin.status',
            displayFcn: (item: any) => item.status,
            type: 'objstring',
            align: 'center'

        },
        {
            name: 'value',
            displayName: 'wholesale.stockin.totalvalue',
            displayFcn: (item: any) => item.value,
            type: 'objstring',
            align: 'center'

        },
        {
            name: 'bottles_damaged_missing',
            displayName: 'wholesale.stockin.totalnoofbottlesdamaged',
            displayFcn: (item: any) => item.bottles_damaged_missing,
            type: 'objstring',
            align: 'center'

        },
    ];
}
